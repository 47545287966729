import React from 'react';
import { Link } from 'gatsby';

function Hyperlink (props) {

  let linkClass = props.linkClass !== undefined && props.linkClass !== null && props.linkClass.length > 0 ? props.linkClass : '';

  let linkTarget = props.linkTarget !== undefined && props.linkTarget !== null && props.linkTarget.length > 0 ? props.linkTarget : '';

  let linkSetup;

  if(props.gatsbylink) {
    linkSetup = (
      <Link to={props.link} title={props.text} className={linkClass}>
        {Boolean(props.children) ? props.children : props.text}
      </Link>
    )
  } else {
    linkSetup = (
      <a href={props.link} title={props.text} className={linkClass} target={linkTarget}>
        {Boolean(props.children) ? props.children : props.text}
      </a>
    )
  }

  return (
    linkSetup
  );
}

export default Hyperlink;
import React from 'react';
import Link from 'components/common/Link';
import { Svg, LiveChat } from 'kh-common-components';
import { isLpgDemergerOn } from 'constants/global';

function Footer () {

    const currentYear = new Date().getFullYear();
    
    return (
        <>
            <footer className="mer mer-global-footer mer-theme--dark" data-contentoffset="true">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-sm-12 col-md-6 mer-global-footer--contacts">
                            <h4 className="mer-global-footer--title">Speak to us</h4>
                            <p className="mer-global-footer--call">
                                <a href="tel:132180">
                                    <Svg title="Phone" 
                                         icon="phone-fill"
                                         aria-hidden="true" />
                                    <span>13 21 80</span>
                                </a>
                            </p>
                            <p className="mer-global-footer--cyan-highlight">
                                Monday to Friday: <time>8:00am</time> - <time>5:00pm</time> AWST
                            </p>

                            <div className="mer-global-footer--emergency">
                                <h4 className="mer-global-footer--title">Emergency numbers</h4>
                                <p className="mer-global-footer--cyan-highlight"><strong>If a life or property is threatened, please call <a href="tel:000" title="Call 000">000</a></strong></p>
                                <ul className="mer-global-footer--emergency-list">
                                    <li>Electricity (Western Power) – 
                                        <a href="tel:131351">13 13 51</a>
                                    </li>
                                    <li>Natural Gas (ATCO Gas) – 
                                        <a href="tel:131352">13 13 52</a>
                                    </li>
                                    {!isLpgDemergerOn &&
                                    <li>LPG emergencies (Cylinders &amp; Tanks) – 
                                        <a href="tel:1800093336">1800 093 336</a>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <a className="mer-global-footer--social-link" href="https://www.facebook.com/kleenheat" target="_blank">
                                        <Svg title="Facebook" 
                                             icon="facebook-fill"
                                             aria-hidden="true" />
                                        <span className="mer-display--visual-hidden">Find Kleenheat on Facebook</span>
                                    </a>
                                    <a className="mer-global-footer--social-link" href="https://www.youtube.com/c/kleenheatgas" target="_blank">
                                        <Svg title="YouTube" 
                                             icon="youtube-fill"
                                             aria-hidden="true" />
                                        <span className="mer-display--visual-hidden">Find Kleenheat on YouTube</span>
                                    </a>
                                    <a className="mer-global-footer--social-link" href="https://instagram.com/kleenheat" target="_blank">
                                        <Svg title="Instagram" 
                                             icon="instagram-fill"
                                             aria-hidden="true" />
                                        <span className="mer-display--visual-hidden">Find Kleenheat on Instagram</span>
                                    </a>
                                </div>

                                <div className="col-12 py-0">
                                    <div className="row justify-content-center">
                                    <div className="col-6 col-sm-4 col-md-5 col-lg-4 text-center">
                                        <a href="/" className="mer-global-footer--branding-link">
                                            <Svg title="Kleenheat" 
                                                 icon="kleenheat-logo"
                                                 aria-hidden="true" />
                                        </a>

                                    </div>
                                    <div className="col-6 col-sm-4 col-md-5 col-lg-4 text-center">
                                        <a href="https://www.wesfarmers.com.au" className="mer-global-footer--branding-link" target="_blank">
                                            <Svg title="Wesfarmers" 
                                                 icon="wesfarmers-logo"
                                                 aria-hidden="true" />
                                        </a>
                                    </div>
                                    </div>
                                </div>

                                <div className="mer-global-footer--terms">
                                    <Link text="Terms of use" 
                                          link="/policies/terms-of-use" />
                                    <Link text="Collection of Information Statement" 
                                          link="/policies/collection-of-information-statement" />
                                    <Link text="Privacy Policy" 
                                          link="/policies/privacy-policy" />
                                </div>
                                <span className="mer-global-footer--copyright">© {currentYear} Kleenheat. All Rights Reserved.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <LiveChat />
        </>
    );
}

export default Footer;